<!--
 * @Description: 联系人管理
 * @Author: ChenXueLin
 * @Date: 2021-10-08 11:13:03
 * @LastEditTime: 2022-05-12 09:35:52
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item
              v-show="!isFormCustomerList"
              class="search-item--1"
              prop="companyId"
            >
              <e6-vr-select
                v-model="searchForm.companyId"
                :data="companyEnum"
                placeholder="所属主体"
                title="所属主体"
                :props="{
                  id: 'companyId',
                  label: 'companyFullName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpId">
              <el-input
                v-if="searchForm.corpName"
                disabled
                placeholder="所属客户"
                title="所属客户"
                v-model="searchForm.corpName"
              ></el-input>
              <e6-vr-select
                v-else
                v-model="searchForm.corpId"
                :data="corpEnum"
                placeholder="客户名称"
                title="客户名称"
                @filterChange="corpHandleFilter"
                :props="{
                  id: 'corpId',
                  label: 'corpName'
                }"
                virtual
                clearable
                remote
                :is-title="true"
                :disabled="isFormCustomerList"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="contactName">
              <el-input
                v-model.trim="searchForm.contactName"
                placeholder="联系人姓名"
                title="联系人姓名"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="telephone">
              <el-input
                v-model.trim="searchForm.telephone"
                placeholder="联系人电话"
                title="联系人电话"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="contactTypeCode">
              <e6-vr-select
                v-model="searchForm.contactTypeCode"
                :data="contactTypeCodeEnum"
                placeholder="联系人职责"
                title="联系人职责"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-history" title="历史记录" @click="toRecord"></i>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd"></i>
          <i
            class="e6-icon-Import_fill"
            v-show="!isFormCustomerList"
            title="导入"
            @click="importXlxs()"
          ></i>
          <i
            class="e6-icon-export_fill"
            v-show="!isFormCustomerList"
            title="导出"
            @click="exportData"
          ></i>
          <!-- <i
            class="e6-icon-clear_line"
            v-show="!isFormCustomerList"
            title="删除"
            @click="handleDelete"
          ></i> -->
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          ref="tableList"
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
        >
          <!-- <el-table-column type="selection" width="40"></el-table-column> -->
          <!-- 序号 start -->
          <el-table-column
            show-overflow-tooltip
            label="序号"
            width="60"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :sortable="column.sortable"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="column.fieldName === 'contactName'">
                {{ row[column.fieldName] }}
              </span>
              <span v-else-if="arrayDataFieldNames.includes(column.fieldName)">
                {{ getArrayDataName(row[column.fieldName]) }}
              </span>
              <span v-else-if="column.fieldName === 'addressList'">
                {{ getAddress(row[column.fieldName]) }}
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="!isFormCustomerList"
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleAdd($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
      <!-- 添加联系人弹框 指定情况下使用 -->
      <add-concat-dialog
        v-if="isFormCustomerList"
        :addContactVisible="addContactVisible"
        :corpId="searchForm.corpId"
        :corpName="searchForm.corpName"
        :isNeedReq="true"
        :displayCrop="true"
        @updateList="queryList()"
        @handleClose="handleClose"
        @handlePush="handlePush"
      ></add-concat-dialog>
    </div>
  </div>
</template>
<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import AddConcatDialog from "@/components/addConcatDialog.vue";
import {
  getContact,
  batchDeleteContact,
  exportContact,
  findDownList,
  getCompanydownList,
  getCorpMsg
} from "@/api";
import { printError, typeOf } from "@/utils/util";
import { exportData } from "@/utils/download";

import { e3Api } from "@/config/api";
const { E3_BL_CORPORATION = "" } = e3Api;
export default {
  name: "contactManage",
  mixins: [listPage, listPageReszie, base],
  components: { AddConcatDialog },
  data() {
    return {
      isFormCustomerList: false, // 是不是从客户列表进来
      addContactVisible: false,
      corpLoading: false,
      queryListAPI: getContact,
      searchForm: {
        companyId: "", //所属主体
        corpId: "", //所属公司
        corpName: "",
        contactName: "", //联系人姓名
        telephone: "", //联系人电话
        contactTypeCode: "", //联系人职责
        pageIndex: 1,
        pageSize: 20,
        sortDir: "",
        sortIndex: ""
      },
      contactTypeCodeEnum: [], //联系人职责枚举
      companyEnum: [], // 主体信息枚举
      corpEnum: [], // 所属公司信息枚举
      total: 0,
      columnData: [
        {
          fieldName: "companyName",
          display: true,
          fieldLabel: "所属主体",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: "custom",
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "所属公司",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: "custom",
          align: "center"
        },
        {
          fieldName: "contactName",
          display: true,
          fieldLabel: "联系人姓名",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: "custom",
          align: "center"
        },
        {
          fieldName: "contactTypeName",
          display: true,
          fieldLabel: "联系人职责",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        },
        {
          fieldName: "phoneList",
          display: true,
          fieldLabel: "电话/手机",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        },
        {
          fieldName: "emailAddr",
          display: true,
          fieldLabel: "邮箱",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        },
        {
          fieldName: "addressList",
          display: true,
          fieldLabel: "地址",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        },
        {
          fieldName: "salesmanName",
          display: true,
          fieldLabel: "业务员",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        },
        {
          fieldName: "updatedByName",
          display: true,
          fieldLabel: "修改人",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        },
        {
          fieldName: "updatedTime",
          display: true,
          fieldLabel: "修改时间",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: "custom",
          align: "center"
        }
      ], //表头
      arrayDataFieldNames: ["phoneList", "contactTypeName"], // 数组类型的字段名 手动写上写死
      selColumnId: [], // 选中的数据
      tableData: [] // 表格数据
    };
  },
  computed: {},
  created() {
    this.queryList();
    this.initData();
    this.corpDownList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //覆盖base重置 作为客户详情子组件时不清空客户ID corpId
    handleReset() {
      this.$refs.searchForm.resetFields();
      this.formCustomerListInit(false);
    },
    // 作为客户详情子组件时 更新列表
    formCustomerListInit(isQuery = true) {
      let hasParamsRoute = this.getHasParamsRoute();
      if (hasParamsRoute.params.corpId) {
        this.searchForm.corpId = hasParamsRoute.params.corpId;
        this.searchForm.corpName = hasParamsRoute.params.corpName;
        this.isFormCustomerList =
          hasParamsRoute.params.origin === "customerManage/customerList";
      }
      if (isQuery) this.queryList();
    },
    // 客户列表 远程搜索
    corpHandleFilter: _.debounce(async function(val) {
      if (val) {
        this.corpDownList(val);
        this.searchForm.corpId = "";
      }
    }, 300),
    async corpDownList(val, enumKey = "corpEnum") {
      try {
        this.corpLoading = true;
        let corpEnumRes = await getCorpMsg({
          pageIndex: 1,
          pageSize: 200,
          corpName: val
        });
        this[enumKey] = this.getFreezeResponse(corpEnumRes, {
          path: "data.array"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.corpLoading = false;
      }
    },
    // 获取基础信息(枚举)
    async initData() {
      try {
        //获取枚举数据 客户类型 客户类别 客户状态 客户业务类别
        let promiseList = [
          findDownList(["contactTypeCode"]),
          getCompanydownList({ isManageEmp: false, companyId: "" })
        ];
        let [findDownListRes, companyEnumRes] = await Promise.all(promiseList);
        this.contactTypeCodeEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.contactTypeCode"
        });
        this.companyEnum = this.getFreezeResponse(companyEnumRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 删除
    handleDelete() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择要操作的数据");
        return;
      } else {
        this.$confirm("是否确认删除所选联系人信息?", "删除联系人信息", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.batchDeleteContact();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除"
            });
          });
      }
    },
    // 导入
    importXlxs() {
      let route = {
        name: "uploadCenter",
        params: {
          refresh: true,
          moduleKey: "contact_import",
          origin: "customerManage/contactManage",
          serverName: E3_BL_CORPORATION
        }
      };
      this.routerPush(route);
    },
    //  导出
    exportData() {
      exportData(this, exportContact);
    },
    // 批量删除，向后台请求
    async batchDeleteContact() {
      try {
        this.loading = true;
        let res = await batchDeleteContact({
          contactIds: this.selColumnId
        });
        if (res.code === "OK") {
          this.$message.success("删除成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 多选
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.contactId;
      });
    },
    // 获取数组类型数据的显示结果
    getArrayDataName(data) {
      if (typeOf(data) !== "Array") {
        return "";
      }
      return data.join("、");
    },
    // 获取显示的地址
    getAddress(data) {
      if (typeOf(data) !== "Array") {
        return "";
      }
      data = data.map(
        ({ provinceName, cityName, areaName, streetName, address }) => {
          let res = "";
          res += provinceName ? provinceName : "";
          res += cityName ? cityName : "";
          res += areaName ? areaName : "";
          res += streetName ? streetName : "";
          res += address ? address : "";
          return res;
        }
      );
      return data.join("、");
    },
    handleClose() {
      this.addContactVisible = false;
    },
    // 日志
    toRecord() {
      this.$router.push({
        name: "contactRecord",
        params: {
          refresh: true
        },
        query: {}
      });
    },
    handlePush() {},
    handleAdd(val, row) {
      if (this.isFormCustomerList) {
        this.addContactVisible = true;
        return;
      }
      this.routerPush({
        name: "customerManage/contactManageAdd",
        params: {
          refresh: true,
          contactId: row && row.contactId,
          origin: "customerManage/contactManage"
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
