var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-view-wrapper pd-10"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"sectionWrapper",staticClass:"content-view section-wrapper",attrs:{"element-loading-background":_vm.loadingBackground}},[_c('table-title',{on:{"resize":_vm.handleTableTitleResize},scopedSlots:_vm._u([{key:"searchForm",fn:function(){return [_c('el-form',{ref:"searchForm",staticClass:"search-list",attrs:{"model":_vm.searchForm}},[_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFormCustomerList),expression:"!isFormCustomerList"}],staticClass:"search-item--1",attrs:{"prop":"companyId"}},[_c('e6-vr-select',{attrs:{"data":_vm.companyEnum,"placeholder":"所属主体","title":"所属主体","props":{
                id: 'companyId',
                label: 'companyFullName'
              },"clearable":""},model:{value:(_vm.searchForm.companyId),callback:function ($$v) {_vm.$set(_vm.searchForm, "companyId", $$v)},expression:"searchForm.companyId"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"corpId"}},[(_vm.searchForm.corpName)?_c('el-input',{attrs:{"disabled":"","placeholder":"所属客户","title":"所属客户"},model:{value:(_vm.searchForm.corpName),callback:function ($$v) {_vm.$set(_vm.searchForm, "corpName", $$v)},expression:"searchForm.corpName"}}):_c('e6-vr-select',{attrs:{"data":_vm.corpEnum,"placeholder":"客户名称","title":"客户名称","props":{
                id: 'corpId',
                label: 'corpName'
              },"virtual":"","clearable":"","remote":"","is-title":true,"disabled":_vm.isFormCustomerList},on:{"filterChange":_vm.corpHandleFilter},model:{value:(_vm.searchForm.corpId),callback:function ($$v) {_vm.$set(_vm.searchForm, "corpId", $$v)},expression:"searchForm.corpId"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"contactName"}},[_c('el-input',{attrs:{"placeholder":"联系人姓名","title":"联系人姓名"},model:{value:(_vm.searchForm.contactName),callback:function ($$v) {_vm.$set(_vm.searchForm, "contactName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"searchForm.contactName"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"telephone"}},[_c('el-input',{attrs:{"placeholder":"联系人电话","title":"联系人电话"},model:{value:(_vm.searchForm.telephone),callback:function ($$v) {_vm.$set(_vm.searchForm, "telephone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"searchForm.telephone"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"contactTypeCode"}},[_c('e6-vr-select',{attrs:{"data":_vm.contactTypeCodeEnum,"placeholder":"联系人职责","title":"联系人职责","props":{
                id: 'codeValue',
                label: 'codeName'
              },"clearable":""},model:{value:(_vm.searchForm.contactTypeCode),callback:function ($$v) {_vm.$set(_vm.searchForm, "contactTypeCode", $$v)},expression:"searchForm.contactTypeCode"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--buttons"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("查询")]),_vm._v(" "),_c('el-button',{staticClass:"reset",on:{"click":_vm.handleReset}},[_vm._v("重置")])],1)],1)]},proxy:true},{key:"operateAction",fn:function(){return [_c('i',{staticClass:"e6-icon-history",attrs:{"title":"历史记录"},on:{"click":_vm.toRecord}}),_vm._v(" "),_c('i',{staticClass:"e6-icon-add_line",attrs:{"title":"新增"},on:{"click":_vm.handleAdd}}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFormCustomerList),expression:"!isFormCustomerList"}],staticClass:"e6-icon-Import_fill",attrs:{"title":"导入"},on:{"click":function($event){return _vm.importXlxs()}}}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFormCustomerList),expression:"!isFormCustomerList"}],staticClass:"e6-icon-export_fill",attrs:{"title":"导出"},on:{"click":_vm.exportData}})]},proxy:true}])}),_vm._v(" "),_c('section',{staticClass:"table-wrapper"},[_c('el-table',{ref:"tableList",attrs:{"border":"","height":_vm.resizeViewHeight + 'px',"data":_vm.tableData,"highlight-current-row":""},on:{"selection-change":_vm.handleSelectionChange,"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"show-overflow-tooltip":"","label":"序号","width":"60","fixed":"left","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v("\n              "+_vm._s(scope.$index +
                  1 +
                  (_vm.searchForm.pageIndex - 1) * _vm.searchForm.pageSize)+"\n            ")])]}}])}),_vm._v(" "),_vm._l((_vm.columnData),function(column,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":column.fieldName,"label":column.fieldLabel,"min-width":column.width,"fixed":column.fixed,"sortable":column.sortable,"align":column.align,"header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [(column.fieldName === 'contactName')?_c('span',[_vm._v("\n              "+_vm._s(row[column.fieldName])+"\n            ")]):(_vm.arrayDataFieldNames.includes(column.fieldName))?_c('span',[_vm._v("\n              "+_vm._s(_vm.getArrayDataName(row[column.fieldName]))+"\n            ")]):(column.fieldName === 'addressList')?_c('span',[_vm._v("\n              "+_vm._s(_vm.getAddress(row[column.fieldName]))+"\n            ")]):_c('span',[_vm._v(_vm._s(row[column.fieldName]))])]}}],null,true)})}),_vm._v(" "),(!_vm.isFormCustomerList)?_c('el-table-column',{attrs:{"fixed":"right","align":"center","label":"操作","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('e6-td-operate',{attrs:{"data":_vm.getOperateList()},on:{"command":function($event){return _vm.handleAdd($event, scope.row)}}})]}}],null,false,4091233476)}):_vm._e()],2)],1),_vm._v(" "),_c('section',{staticClass:"pagination-wrapper fixed-section"},[_c('el-pagination',{attrs:{"page-size":_vm.searchForm.pageSize,"current-page":_vm.searchForm.pageIndex,"page-sizes":_vm.pageSizes,"layout":_vm.layout,"total":_vm.total,"background":""},on:{"update:pageSize":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:currentPage":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"update:current-page":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_vm._v(" "),(_vm.isFormCustomerList)?_c('add-concat-dialog',{attrs:{"addContactVisible":_vm.addContactVisible,"corpId":_vm.searchForm.corpId,"corpName":_vm.searchForm.corpName,"isNeedReq":true,"displayCrop":true},on:{"updateList":function($event){return _vm.queryList()},"handleClose":_vm.handleClose,"handlePush":_vm.handlePush}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }